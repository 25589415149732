import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from '../views/home/home.component';
import {GalleryComponent} from '../views/gallery/gallery.component';
import {ContactComponent} from '../views/contact/contact.component';
import {AdminGalleryComponent} from '../views/admin/admin-gallery/admin-gallery.component';
import {AdminGuard} from '../guards/admin.guard';
import {LoginComponent} from '../views/login/login.component';
import {AdminGalleryAlbumComponent} from '../views/admin/admin-gallery/admin-gallery-album/admin-gallery-album.component';
import {AlbumComponent} from '../views/album/album.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  // {path: 'ponuka', component: InventoryComponent},
  {path: 'fotogaleria', component: GalleryComponent},
  {path: 'fotogaleria/:slug', component: AlbumComponent},
  {path: 'kontakt', component: ContactComponent},

  {path: 'login', component: LoginComponent},
  {path: 'admin/fotogaleria', component: AdminGalleryComponent, canActivate: [AdminGuard]},
  {path: 'admin/fotogaleria/album/:slug', component: AdminGalleryAlbumComponent, canActivate: [AdminGuard]},

  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
