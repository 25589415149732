import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {Album} from '../../interfaces/album.interface';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  constructor(private http: HttpClient) {
  }

  public getAlbums(): Observable<Album[]> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ALBUM}`;
    return this.http.get<Album[]>(url);
  }

  public getAlbum(slug: string): Observable<Album> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ALBUM}/${slug}`;
    return this.http.get<Album>(url);
  }
}
