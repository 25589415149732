<div class="container">
  <mat-toolbar class="fixed"
               color="primary"
               fxLayout="row"
               fxLayoutGap="16px">
    <button (click)="sidenav.toggle();"
            mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>

    <span>Kvetinárstvo u Dagmary</span>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav
                 class="fixed"
                 mode="over">
      <mat-nav-list (click)="sidenav.toggle()">
        <a class="domov"
           mat-list-item
           routerLink="/">
          <mat-icon>home</mat-icon>
          <span class="nav-caption">Domov</span>
        </a>
        <!--<a class="ponuka" mat-list-item routerLink="/ponuka">
          <mat-icon>local_florist</mat-icon>
          <span class="nav-caption">Ponuka</span>
        </a>-->
        <a class="fotogaleria"
           mat-list-item
           routerLink="/fotogaleria">
          <mat-icon>photo_library</mat-icon>
          <span class="nav-caption">Fotogaléria</span>
        </a>
        <a class="kontakt"
           mat-list-item
           routerLink="/kontakt">
          <mat-icon>account_circle</mat-icon>
          <span class="nav-caption">Kontakt</span>
        </a>
        <mat-divider></mat-divider>
        <ng-container *ngIf="authService.isAuthenticated">
          <mat-list-item role="listitem"
                         [disableRipple]="true"
                         (click)="$event.stopPropagation()">Administrácia
          </mat-list-item>
          <a class="admin"
             mat-list-item
             routerLink="/admin/fotogaleria">
            <mat-icon>photo_library</mat-icon>
            <span class="nav-caption">Fotogaléria</span>
          </a>
          <!--<a class="admin"
             mat-list-item
             routerLink="/admin/pobocky">
            <mat-icon>store</mat-icon>
            <span class="nav-caption">Pobočky</span>
          </a>-->

          <a (click)="onLogout()"
             class="admin"
             mat-list-item>
            <mat-icon>exit_to_app</mat-icon>
            <span class="nav-caption">Odhlásiť sa</span>
          </a>
        </ng-container>
        <ng-container *ngIf="!authService.isAuthenticated">
          <a class="admin"
             mat-list-item
             routerLink="/login">
            <mat-icon>how_to_reg</mat-icon>
            <span class="nav-caption">Prihlásiť sa</span>
          </a>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-header [fullScreenHeader]="fullScreenHeader"></app-header>
      <router-outlet></router-outlet>

      <mat-card class="mh-16 mb-16">
        <mat-card-title class="fs-1-2">Partneri</mat-card-title>
        <mat-card-content fxLayout="row wrap">
          <a href="https://www.hotelmaraton.sk/"
             class="partner contain bp-center m-16"
             [ngStyle]="{'background-image': 'url(assets/images/partneri/hotel-maraton.png)'}"
             fxFlex="0 0 calc((1 / 2 * 100%) - 32px)"
             fxFlex.gt-sm="0 0 calc((1 / 3 * 100%) - 32px)"
             fxFlex.gt-md="0 0 calc((1 / 6 * 100%) - 32px)"></a>
        </mat-card-content>
      </mat-card>

      <footer class="mb-16">
        <mat-card class="mat-elevation-z0"
                  fxLayout
                  fxLayoutAlign="center center">
          <span>Copyright © 2020 Kvetinárstvo u Dagmary</span>
        </mat-card>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
