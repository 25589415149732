import {Component} from '@angular/core';

import {Branch} from '../../interfaces/branch.interface';
import {branches} from '../../const/branches.const';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  readonly dayNames: string[] = ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'];
  branches: Branch[] = branches;
}
