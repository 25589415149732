import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BytesPipe} from 'angular-pipes';

import {environment} from '../../../../../environments/environment';
import {Album} from '../../../../interfaces/album.interface';
import {GalleryService} from '../../../gallery/gallery.service';
import {SnackbarService} from '../../../../services/snackbar.service';
import {AdminService} from '../../../../services/admin.service';
import {Photo} from '../../../../interfaces/photo.interface';

@Component({
  selector: 'app-admin-gallery-album',
  templateUrl: './admin-gallery-album.component.html',
  styleUrls: ['./admin-gallery-album.component.scss', '../../../album/album.component.scss']
})
export class AdminGalleryAlbumComponent implements OnInit {
  public readonly ENDPOINT_UPLOADS_THUMBNAILS = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.UPLOADS_THUMBNAILS}`;
  public readonly UPLOAD_MAX_FILE_SIZE_TRANSFORM = new BytesPipe().transform(environment.UPLOAD_MAX_FILE_SIZE, 2);
  public readonly UPLOAD_FILE_TYPES_STRING = environment.UPLOAD_FILE_TYPES.join(',');

  public uploadInProgress = false;
  public filesToUpload: File[] = [];
  public album: Album = null;
  private uploadingSubscription: Subscription = null;
  private slug: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService,
              private galleryService: GalleryService,
              private adminService: AdminService) {
  }

  ngOnInit() {
    this.route.params.subscribe(queryParams => {
      this.slug = queryParams.slug;
      this.fetch();
    });
  }

  public onChange(e: Event) {
    this.addFiles((e.target as HTMLInputElement).files);
  }

  public onDrop(files: File[]) {
    this.addFiles(files);
  }

  public onCancel() {
    this.filesToUpload.splice(0, this.filesToUpload.length);
    this.uploadingSubscription.unsubscribe();
    this.uploadInProgress = false;
  }

  public onSort(photos: Photo[]) {
    this.album.photos = photos;
    this.onPhotosChanged();
  }

  public onDelete(index: number) {
    const photo = this.album.photos.splice(index, 1)[0];
    this.adminService.deletePhoto(photo.id).subscribe();
  }

  private addFiles(files: FileList | File[]) {
    Array.prototype.forEach.call(files, f => {
      if (f.size > environment.UPLOAD_MAX_FILE_SIZE) {
        this.snackbarService.add(`Súbor "${f.name}" je väčší ako ${this.UPLOAD_MAX_FILE_SIZE_TRANSFORM}`, 'error');
        return;
      }

      if (!environment.UPLOAD_FILE_TYPES.includes(f.type)) {
        this.snackbarService.add(`Súbor "${f.name}" nie je podporovaný`, 'error');
        return;
      }

      this.filesToUpload.push(f);
    });

    this.uploadFiles();
  }

  private uploadFiles() {
    if (this.uploadInProgress === false && this.filesToUpload.length > 0) {
      this.uploadInProgress = true;

      const f = this.filesToUpload.shift();

      this.uploadingSubscription = this.adminService.addFile(f).subscribe(
        data => {
          this.album.photos.push(data);
          this.onPhotosChanged();

          this.uploadInProgress = false;
          this.uploadFiles();
        },
        e => {
          this.snackbarService.addStatusError(+e.statusText);
          this.snackbarService.add(`Súbor "${f.name}" sa nepodarilo nahrať, skúste znova neskôr`, 'error');

          this.uploadInProgress = false;
          this.uploadFiles();
        }
      );
    }
  }

  private onPhotosChanged() {
    const photoIDs = this.album.photos.map(p => p.id).join(',');
    this.adminService.setPhotos(this.album.id, photoIDs).subscribe();
  }

  private fetch() {
    this.galleryService.getAlbum(this.slug).subscribe(
      data => {
        this.album = data;
      },
      e => {
        this.snackbarService.addStatusError(+e.statusText);
      }
    );
  }
}
