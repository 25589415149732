<h1 class="mat-h1 mh-16 mt-16 mb-0">Fotogaléria</h1>

<div *ngIf="album === null"
     class="p-16 ta-center">
  <mat-spinner class="mh-auto mv-16"></mat-spinner>
  <h3 class="mat-h3 m-0">Prebieha načítavanie albumu...</h3>
</div>

<ng-container *ngIf="album !== null">
  <h2 class="mat-h2 mh-16 mt-8 mb-16">{{ album.name }}</h2>

  <div *ngIf="album.photos.length === 0"
       class="mh-16 o-hidden">V tomto albume sa nenachádzajú žiadne fotky
  </div>

  <div *ngIf="album.photos.length > 0"
       class="mt-16 ml-16"
       fxLayout="row wrap">
    <div *ngFor="let photo of photos; let index = index"
         class="photo p-0 mr-16 mb-16"
         fxFlex="0 1 calc(50% - 16px)"
         fxFlex.gt-lg="0 1 calc(12.5% - 16px)"
         fxFlex.gt-md="0 1 calc(16.6667% - 16px)"
         fxFlex.gt-sm="0 1 calc(25% - 16px)"
         fxFlex.gt-xs="0 1 calc(33.3333% - 16px)">
      <div (click)="open(index)"
           [ngStyle]="{'background-image': 'url(' + photo.thumb + ')'}"
           class="photo__photo contain bp-center"></div>
    </div>
  </div>
</ng-container>
