import {Component, OnInit} from '@angular/core';
import {SnackbarService} from '../../services/snackbar.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(private router: Router,
              private authService: AuthService,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/']);
    }
  }

  onSubmit() {
    const values = this.form.value;
    const username = values.username;
    const password = values.password;

    this.authService.login(username, password).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      e => {
        this.snackbarService.addStatusError(+e.statusText);
      });
  }
}
