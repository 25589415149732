<div class="p-16"
     fxLayout="column"
     fxLayout.gt-xs="row"
     fxLayoutAlign.gt-xs="start start"
     fxLayoutGap="16px">
  <mat-card fxFlex="100%"
            fxFlex.gt-sm="70%"
            fxFlex.gt-xs="60%">
    <h2 class="mat-h2">Vitajte na stránkach nášho kvetinárstva.</h2>
    <!--<p>Sme firma s dlhoročnými skúsenosťami, ktoré sa snažíme čo najlepšie odovzdávať našim zákazníkom.</p>
    <p>U nás nájdete široký sortiment rezaných a črepových kvetov, pri výbere ktorých Vám ochotne poradí náš mladý, ale
      skúsený kolektív. A ak nadíde Váš svadobný deň, aj vtedy sme pripravení ponúknuť Vám svadobnú kyticu, aranžmány so
      živých kvetov, či výzdobu auta. Veríme, že každý si u nás niečo nájde buď pre svoje potešenie, alebo pre potešenie
      iných.</p>
    <p>Tešíme sa na Vás.</p>-->

    <p>Sme firma s dlhoročnými skúsenosťami, ktorá počas týchto rokov Vám prináša radosť v podobe kytíc a iných
       kvetinových aranžmánov a dekorácií. Ponúkame Vám záhradnícke a kvetinárske služby na profesionálnej úrovni.</p>

    <h2 class="mat-h2 mt-32 mb-0 uppercase">Aranžujeme</h2>
    <ul>
      <li>
        <p class="mv-8">kytice príležitostné, svadobné, smútočné a aranžmány zo živých a umelých kvetín</p>
      </li>
    </ul>

    <h2 class="mat-h2 mt-32 mb-0 uppercase">Navrhujeme a realizujeme</h2>
    <ul>
      <li>
        <p class="mv-8">kvetinovú výzdobu interiérov a exteriérov pre firmy, reštaurácie, ale aj pre
                        domácnosti</p>
      </li>
      <li>
        <p class="mv-8">výzdobu svadobných miestností a kostolov</p>
      </li>
    </ul>

    <h2 class="mat-h2 mt-32 mb-0 uppercase">Zabezpečujeme</h2>
    <ul>
      <li>
        <p class="mv-8">údržbu a ošetrovanie črepníkových rastlín vo firmách</p>
      </li>
      <li>
        <p class="mv-8">donášku kvetov na základe telefonických a osobných objednávok v rámci Košíc a
                        okolia</p>
      </li>
    </ul>

    <h2 class="mat-h2 mt-32 mb-0 uppercase">Prenajímame</h2>
    <ul>
      <li>
        <p class="mv-8">pre pripravované udalosti k svadobnej a kvetinovej výzdoby - vázy, zrkadlá, svietniky,
                        lampáše, paravany a iné potrebné príslušenstvo a dekorácie</p>
      </li>
    </ul>
  </mat-card>
  <mat-card fxFlex="100%"
            fxFlex.gt-sm="30%"
            fxFlex.gt-xs="40%">
    <h2 class="mat-h2">Kde nás nájdete</h2>

    <ng-container *ngFor="let branch of branches">
      <h3 class="mat-h3 mb-0 mt-16">{{branch.name}}</h3>
      <address>{{branch.address.street}} {{branch.address.number}},
        {{branch.address.zip}} {{branch.address.city}}</address>
      <p class="mt-8 mb-0 fw-600 fs-1-1">{{branch.contact.phone[0]}}</p>
    </ng-container>

    <button
      class="mt-16"
      color="primary"
      mat-flat-button
      routerLink="/kontakt">Zobraziť viac informácií
    </button>
  </mat-card>
</div>
