export const ENVIRONMENT_SHARED = {
  ENDPOINT: {
    ALBUM: '/api/album',

    UPLOADS: '/uploads/',
    UPLOADS_THUMBNAILS: '/uploads/thumbnails/',

    LOGIN: '/api/login',
    ADMIN: {
      ALBUM: '/api/admin/album',
      PHOTO: '/api/admin/photo',
    }
  },

  DIALOG_WIDTH: '640px',
  UPLOAD_MAX_FILE_SIZE: 16777216, // 16M
  UPLOAD_FILE_TYPES: ['image/jpeg', 'image/png'],
};
