<form (ngSubmit)="onSubmit()"
      [formGroup]="form">
  <h1 mat-dialog-title>Odstrániť album "{{ data.album.name }}"</h1>
  <div mat-dialog-content>
    <p>Ste si istý, že chcete odstrániť tento album?</p>
    <p>Ak chcete odstrániť tento album napíšte "ODSTRANIT" do poľa nižšie.</p>
    <mat-form-field>
      <input formControlName="confirm"
             matInput
             placeholder="Potvrdenie"
             type="text">
      <mat-error *ngIf="hasError('required')">Toto pole je povinné</mat-error>
      <mat-error *ngIf="hasError('pattern')">Napíšte "ODSTRANIT" do tohto poľa</mat-error>
    </mat-form-field>
  </div>
  <div align="end"
       fxLayoutGap="8px"
       mat-dialog-actions>
    <button (click)="close()"
            mat-flat-button
            type="button">Zrušiť
    </button>
    <button [disabled]="form.invalid || form.pristine"
            color="warn"
            mat-flat-button
            type="submit">Ok
    </button>
  </div>
</form>
