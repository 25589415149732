import {AbstractControl} from '@angular/forms';

import {Album} from '../interfaces/album.interface';

export function checkAlbumName(albums: Album[]) {
  return (control: AbstractControl) => {
    const value = control.value.toLowerCase();

    const found = albums.find(f => {
      return f.name.toLowerCase() === value;
    });

    if (found) {
      return {exists: true};
    }

    return null;
  };
}
