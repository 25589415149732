<h1 class="mat-h1 mh-16 mt-16 mb-0">Prihlásenie</h1>

<div fxLayoutAlign="center center">
  <mat-card class="mb-16">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()"
            [formGroup]="form"
            fxLayout="column"
            fxLayoutGap="16px">
        <mat-form-field>
          <input formControlName="username"
                 matInput
                 placeholder="Username">
        </mat-form-field>
        <mat-form-field>
          <input formControlName="password"
                 matInput
                 placeholder="Password"
                 type="password">
        </mat-form-field>
        <button [disabled]="!form.valid"
                color="primary"
                mat-flat-button
                type="submit">Odoslať
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
