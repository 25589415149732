import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private shown = false;
  private snackList: { message: string, style: string, duration: number }[] = [];

  private statusErrors = {
    0: 'Vyskytla sa neočakávaná chyba, skúste znova neskôr',

    101: 'Neplatné meno a heslo',
    102: 'Nemáte oprávnenie pre vykonanie tejto akcie',

    201: 'Album s týmto názvom už existuje',
    202: 'Tento album neexistuje'
  };

  constructor(private snackBar: MatSnackBar) {
  }

  add(message: string, style = 'success', duration = 2500) {
    this.snackList.push({message, style, duration});

    if (!this.shown) {
      this.show();
    }
  }

  addStatusError(error: number) {
    const e = this.statusErrors[error] || this.statusErrors[0];
    this.add(e, 'error');
  }

  private show() {
    if (this.snackList.length > 0) {
      this.shown = true;
      const data = this.snackList[0];

      this.snackBar.open(data.message, '', {
        duration: data.duration,
        panelClass: [`snackbar-${data.style}`]
      }).afterDismissed().subscribe(e => {
        this.shown = false;
        this.snackList.shift();
        this.show();
      });
    }
  }
}
