import {Component} from '@angular/core';

import {InventoryItem} from '../../interfaces/inventory-item.interface';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent {
  inventory: InventoryItem[] = [
    {name: 'Svadobné kytice', image: '../../assets/images/ponuka/SvadobneKytice_01.jpg'},
    {name: 'Svadobná výzdoba', image: '../../assets/images/ponuka/SvadobnaVyzdoba_01.jpg'},
    {name: 'Gratulačné kytice', image: '../../assets/images/ponuka/GratulacneKytice_01.jpg'},
    {name: 'Smútočné kytice a vence', image: '../../assets/images/ponuka/SmutocneKyticeAVence_01.jpg'},
    {name: 'Výzdoba interiérov na slávnostné príležitosti', image: '../../assets/images/ponuka/VyzdobaInterierov_01.jpg'},
    {name: 'Predaj črepových rastlín a starostlivosť o ne', image: '../../assets/images/ponuka/CrepoveRastliny_01.jpg'}
  ];
}
