import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  fullScreenHeader = true;

  constructor(private router: Router,
              public authService: AuthService) {
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (!(e instanceof NavigationEnd)) {
        return;
      }

      this.fullScreenHeader = e.url === '/';
    });
  }
}
