<h1 class="mat-h1 mh-16 mt-16 mb-0">Ponuka</h1>
<div class="pt-16 pl-16"
     fxLayout="row wrap">
  <mat-card *ngFor="let item of inventory"
            [ngStyle]="{'background-image': 'url(' + item.image + ')'}"
            [title]="item.name"
            class="mb-16 mr-16 cover bp-center"
            fxFlex="100%"
            fxFlex.gt-sm="0 1 calc(33.33% - 16px)"
            fxFlex.gt-xs="0 1 calc(50% - 16px)">
    <p class="block lh-20 m-0 p-8 white ta-center">{{ item.name }}</p>
  </mat-card>
</div>
