import {ENVIRONMENT_SHARED} from './environment.shared';

export const environment = {
  ...ENVIRONMENT_SHARED,
  production: false,
  ENDPOINT: {
    ...ENVIRONMENT_SHARED.ENDPOINT,
    HOST: 'https://www.kvetinarstvoudagmary.sk',
  },
};
