import {Component, OnInit} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {DialogService} from '../../../services/dialog.service';
import {CreateAlbumDialogComponent} from './dialog/create-album-dialog/create-album-dialog.component';
import {Album} from '../../../interfaces/album.interface';
import {GalleryService} from '../../gallery/gallery.service';
import {RenameAlbumDialogComponent} from './dialog/rename-album-dialog/rename-album-dialog.component';
import {DeleteAlbumDialogComponent} from './dialog/delete-album-dialog/delete-album-dialog.component';

@Component({
  selector: 'app-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['../../gallery/gallery.component.scss']
})
export class AdminGalleryComponent implements OnInit {
  public readonly ENDPOINT_UPLOADS_THUMBNAILS = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.UPLOADS_THUMBNAILS}`;

  public albums: Album[] = null;

  constructor(private dialogService: DialogService,
              private galleryService: GalleryService) {
  }

  ngOnInit() {
    this.fetchAlbums();
  }

  onCreateAlbum() {
    this.dialogService.openDialog(CreateAlbumDialogComponent, {
      albums: this.albums
    }, () => {
      this.fetchAlbums();
    });
  }

  onRenameAlbum(album: Album) {
    this.dialogService.openDialog(RenameAlbumDialogComponent, {
      albums: this.albums,
      album
    }, () => {
      this.fetchAlbums();
    });
  }

  onDeleteAlbum(album: Album) {
    this.dialogService.openDialog(DeleteAlbumDialogComponent, {
      album
    }, () => {
      this.fetchAlbums();
    });
  }

  private fetchAlbums() {
    this.albums = null;
    this.galleryService.getAlbums().subscribe(
      data => {
        this.albums = data;
      }
    );
  }
}
