<h1 class="mat-h1 m-16">Fotogaléria</h1>

<div *ngIf="albums === null"
     class="p-16 ta-center">
  <mat-spinner class="mh-auto mv-16"></mat-spinner>
  <h3 class="mat-h3 m-0">Prebieha načítavanie albumov...</h3>
</div>

<ng-container *ngIf="albums !== null">
  <p *ngIf="albums.length=== 0"
     class="p-16">Zatiaľ nebol vytvorený žiadny album vo fotogalérii</p>

  <div *ngIf="albums.length > 0"
       class="mt-16 ml-16"
       fxLayout="row wrap">
    <mat-card *ngFor="let album of albums"
              class="album o-hidden p-0 mr-16 mb-16"
              fxFlex="100%"
              fxFlex.gt-md="0 1 calc(25% - 16px)"
              fxFlex.gt-sm="0 1 calc(50% - 16px)">
      <h4 class="mat-h4 m-16 lh-40"
          fxLayout="row">
        <a [routerLink]="album.slug">{{ album.name }}
          <span class="album__photo-count">({{ album.photos.length }})</span></a>
      </h4>
      <mat-card-content class="m-0 p-0" [routerLink]="album.slug">
        <div *ngIf="album.photos.length === 0"
             class="p-16 ta-center">Žiadne fotky
        </div>
        <div class="mt--2 mr--2 mb--2"
             fxLayout="row wrap">
          <div *ngFor="let photo of album.photos | slice:0:16"
               class="mr-2 mb-2"
               fxFlex="0 1 calc(25% - 2px)"
               fxFlex.gt-sm="0 1 calc(12.5% - 2px)">
            <div [ngStyle]="{'background-image': 'url(' + ENDPOINT_UPLOADS_THUMBNAILS +  photo.filename + ')'}"
                 class="album__photo cover"></div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
