import {Component, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';
import {Album} from '../../interfaces/album.interface';
import {GalleryService} from './gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public readonly ENDPOINT_UPLOADS_THUMBNAILS = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.UPLOADS_THUMBNAILS}`;

  public albums: Album[] = null;

  constructor(private galleryService: GalleryService) {
  }

  ngOnInit() {
    this.fetchAlbums();
  }

  private fetchAlbums() {
    this.galleryService.getAlbums().subscribe(
      data => {
        this.albums = data;
      }
    );
  }
}
