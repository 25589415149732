import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IAlbum, Lightbox} from 'ngx-lightbox';

import {environment} from '../../../environments/environment';
import {GalleryService} from '../gallery/gallery.service';
import {Album} from '../../interfaces/album.interface';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  public readonly ENDPOINT_UPLOADS = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.UPLOADS}`;
  public readonly ENDPOINT_UPLOADS_THUMBNAILS = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.UPLOADS_THUMBNAILS}`;
  public album: Album = null;
  public photos: IAlbum[] = [];
  private slug: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService,
              private galleryService: GalleryService,
              private lightbox: Lightbox) {
  }

  ngOnInit() {
    this.route.params.subscribe(queryParams => {
      this.slug = queryParams.slug;
      this.fetch();
    });
  }

  open(index: number): void {
    this.lightbox.open(this.photos, index);
  }

  private fetch() {
    this.album = null;
    this.galleryService.getAlbum(this.slug).subscribe(
      data => {
        this.album = data;

        this.photos = this.album.photos.map(p => {
          return {
            src: `${this.ENDPOINT_UPLOADS + p.filename}`,
            thumb: `${this.ENDPOINT_UPLOADS_THUMBNAILS + p.filename}`
          };
        });
      },
      e => {
        this.snackbarService.addStatusError(+e.statusText);
      }
    );
  }
}
