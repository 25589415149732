import {Branch} from '../interfaces/branch.interface';

export const branchKomenskeho: Branch = {
  name: 'Prevádzka Komenského',
  contact: {
    phone: ['0915 630 500', '055 632 02 32'],
    email: ['kvetinarstvodagmar@gmail.com']
  },
  openingHours: [
    ['9:00', '17:00'], // Monday
    ['9:00', '17:00'], // Tuesday
    ['9:00', '17:00'], // Wednesday
    ['9:00', '17:00'], // Thursday
    ['9:00', '17:00'], // Friday
    ['8:00', '13:00'], // Saturday
    undefined, // Sunday
  ],
  address: {
    street: 'Komenského',
    number: 69,
    zip: '04001',
    city: 'Košice',
    map: 'Kvetinárstvo u Dagmary (Komenského 69): Komenského 69, 04001 Košice - Sever, 040 01 Košice'
  }
};
