import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {User} from '../interfaces/user.interface';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser: User = this.authService.currentUser;

    if (currentUser && currentUser.token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${currentUser.token}`)
      });
    }

    return next.handle(req);
  }
}
