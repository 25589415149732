<form (ngSubmit)="onSubmit()"
      [formGroup]="form">
  <h1 mat-dialog-title>Vytvoriť nový album</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input formControlName="name"
             matInput
             placeholder="Názov albumu"
             type="text">
      <mat-error *ngIf="hasError('name', 'required')">Musíte zadať názov albumu</mat-error>
      <mat-error *ngIf="hasError('name', 'minlength')">Názov albumu musí obsahovať aspoň 3 znaky</mat-error>
      <mat-error *ngIf="hasError('name', 'maxlength')">Názov albumu môže obsahovať maximálne 128 znakov</mat-error>
      <mat-error *ngIf="hasError('name', 'exists')">Album s týmto názvom už existuje</mat-error>
    </mat-form-field>
  </div>
  <div align="end"
       fxLayoutGap="8px"
       mat-dialog-actions>
    <button (click)="close()"
            mat-flat-button
            type="button">Zrušiť
    </button>
    <button [disabled]="form.invalid || form.pristine"
            color="primary"
            mat-flat-button
            type="submit">Ok
    </button>
  </div>
</form>
