<h1 class="mat-h1 mh-16 mt-16 mb-0">Kontakt</h1>
<mat-card class="m-16 p-0 o-hidden">
  <div fxLayout="column"
       fxLayout.gt-sm="row"
       fxLayoutGap="16px">
    <div class="pl-16 pb-16 mt--48"
         fxFlex="100%"
         fxFlex.gt-sm="50%">
      <div *ngFor="let branch of branches">
        <h1 class="mat-h1 mt-64 mb-16">{{ branch.name }}</h1>

        <h2 class="mat-h2 mt-16 mb-8">Adresa</h2>
        <p>
          {{ branch.address.street }} {{ branch.address.number }},<br>
          {{ branch.address.zip }} {{ branch.address.city }}
        </p>

        <h2 class="mat-h2 mt-16 mb-8">Kontakt</h2>
        <table>
          <tbody>
            <tr *ngIf="branch.contact.phone !== undefined">
              <td class="p-2">Telefónne číslo:</td>
              <td class="p-2">
                <p *ngFor="let phone of branch.contact.phone"
                   class="m-0 mb-2 fw-600">{{ phone }}</p>
              </td>
            </tr>
            <tr *ngIf="branch.contact.email !== undefined">
              <td class="p-2">E-mail:</td>
              <td class="p-2">
                <p *ngFor="let email of branch.contact.email"
                   class="m-0 mb-2">
                  <a href="mailto:{{ email }}">{{ email }}</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h2 class="mat-h2 mt-16 mb-8">Otváracie hodiny</h2>
        <table class="opening-hours">
          <tbody>
            <ng-container *ngFor="let openingHours of branch.openingHours; let index = index">
              <tr>
                <td class="p-2">{{ dayNames[index] }}</td>
                <td class="p-2"
                    *ngIf="openingHours !== undefined">{{ openingHours[0] }} - {{ openingHours[1] }}</td>
                <td class="p-2"
                    *ngIf="openingHours === undefined">Zatvorené</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div fxFlex="100%"
         fxFlex.gt-sm="50%">
      <iframe src="https://www.google.com/maps/d/embed?mid=1Wac2epJ4i2Ld7kPDDgZurHGEjSzfv9cx"></iframe>
    </div>
  </div>

</mat-card>
