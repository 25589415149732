import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgMathPipesModule} from 'angular-pipes';
import {LightboxModule} from 'ngx-lightbox';
import {DragulaModule} from 'ng2-dragula';

import {AppRoutingModule} from './modules/app-routing.module';
import {AppComponent} from './app.component';
import {MaterialModule} from './modules/material.module';
import {HomeComponent} from './views/home/home.component';
import {InventoryComponent} from './views/inventory/inventory.component';
import {GalleryComponent} from './views/gallery/gallery.component';
import {ContactComponent} from './views/contact/contact.component';
import {AdminGalleryComponent} from './views/admin/admin-gallery/admin-gallery.component';
import {SafePipe} from './pipes/safe.pipe';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {LoginComponent} from './views/login/login.component';
import {AuthService} from './services/auth.service';
import {CreateAlbumDialogComponent} from './views/admin/admin-gallery/dialog/create-album-dialog/create-album-dialog.component';
import {AdminGalleryAlbumComponent} from './views/admin/admin-gallery/admin-gallery-album/admin-gallery-album.component';
import {RenameAlbumDialogComponent} from './views/admin/admin-gallery/dialog/rename-album-dialog/rename-album-dialog.component';
import {DropZoneDirective} from './directives/drop-zone.directive';
import {DeleteAlbumDialogComponent} from './views/admin/admin-gallery/dialog/delete-album-dialog/delete-album-dialog.component';
import {AlbumComponent} from './views/album/album.component';
import {HeaderComponent} from './views/header/header.component';

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    HomeComponent,
    InventoryComponent,
    GalleryComponent,
    AlbumComponent,
    ContactComponent,
    AdminGalleryComponent,
    LoginComponent,
    AdminGalleryAlbumComponent,
    DropZoneDirective,
    CreateAlbumDialogComponent,
    RenameAlbumDialogComponent,
    DeleteAlbumDialogComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    NgMathPipesModule,
    LightboxModule,
    DragulaModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
