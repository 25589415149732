import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../../services/snackbar.service';
import {Album} from '../../../../../interfaces/album.interface';
import {AdminService} from '../../../../../services/admin.service';

@Component({
  selector: 'app-delete-album',
  templateUrl: './delete-album-dialog.component.html',
  styleUrls: ['../../../../../../styles-dialog.scss']
})
export class DeleteAlbumDialogComponent {
  public form: FormGroup = new FormGroup({
    confirm: new FormControl('', [
      Validators.required,
      Validators.pattern('^ODSTRANIT$')
    ])
  });

  constructor(private dialogRef: MatDialogRef<DeleteAlbumDialogComponent>,
              private snackbarService: SnackbarService,
              private adminService: AdminService,
              @Inject(MAT_DIALOG_DATA) public data: {
                album: Album
              }) {
  }

  hasError(error) {
    const errorCount = this.form.controls.confirm.errors ? Object.keys(this.form.controls.confirm.errors).length : 0;
    return this.form.controls.confirm.hasError(error) && errorCount === 1;
  }

  close(result = null) {
    this.dialogRef.close(result);
  }

  onSubmit() {
    this.form.markAsPristine();

    this.adminService.deleteAlbum(this.data.album.id).subscribe(
      () => {
        this.snackbarService.add('Album odstránený');
        this.close(true);
      },
      e => {
        this.snackbarService.addStatusError(+e.statusText);
      }
    );
  }
}
