<h1 class="mat-h1 mh-16 mt-16 mb-0">Administrácia</h1>
<h2 class="mat-h2 mh-16 mt-8 mb-0">Fotogaléria</h2>

<div *ngIf="album === null"
     class="p-16 ta-center">
  <mat-spinner class="mh-auto mv-16"></mat-spinner>
  <h3 class="mat-h3 m-0">Prebieha načítavanie albumu...</h3>
</div>

<ng-container *ngIf="album !== null">
  <input #inputUploadFiles
         (change)="onChange($event)"
         [accept]="UPLOAD_FILE_TYPES_STRING"
         class="hidden"
         multiple
         type="file">
  <div (appDropZone)="onDrop($event)"
       (click)="inputUploadFiles.click()"
       class="drop-zone m-16 p-16 br-16 ta-center">
    <h3 class="mt-16 mb-8">Presuňte súbory sem</h3>
    <h4 class="m-0 fw-400">alebo</h4>
    <h3 class="mb-16 mt-8">Kliknite sem pre otvorenie dialógového okna</h3>
  </div>

  <h3 class="mat-h3 mh-16 mt-32 mb-16">{{ album.name }}</h3>
  <div *ngIf="album.photos.length === 0"
       class="mh-16 o-hidden">V tomto albume sa nenachádzajú žiadne fotky
  </div>
  <div (dragulaModelChange)="onSort($event)"
       [dragulaModel]="album.photos"
       class="mt-16 ml-16"
       dragula="AlbumPhotos"
       fxLayout="row wrap">
    <mat-card *ngFor="let photo of album.photos; let index = index"
              class="photo p-0 mr-16 mb-16"
              fxFlex="0 1 calc(50% - 16px)"
              fxFlex.gt-lg="0 1 calc(12.5% - 16px)"
              fxFlex.gt-md="0 1 calc(16.6667% - 16px)"
              fxFlex.gt-sm="0 1 calc(25% - 16px)"
              fxFlex.gt-xs="0 1 calc(33.3333% - 16px)">
      <div [ngStyle]="{'background-image': 'url(' + ENDPOINT_UPLOADS_THUMBNAILS + photo.filename + ')'}"
           class="photo__photo contain bp-center"></div>
      <button (click)="onDelete(index)"
              class="photo__button-delete"
              color="warn"
              mat-mini-fab>
        <mat-icon>close</mat-icon>
      </button>
    </mat-card>

    <mat-card *ngIf="uploadInProgress"
              class="photo p-0 mr-16 mb-16"
              fxFlex="0 1 calc(50% - 16px)"
              fxFlex.gt-lg="0 1 calc(12.5% - 16px)"
              fxFlex.gt-md="0 1 calc(16.6667% - 16px)"
              fxFlex.gt-sm="0 1 calc(25% - 16px)"
              fxFlex.gt-xs="0 1 calc(33.3333% - 16px)">

      <div class="photo__progress">
        <div class="p-16"
             fxLayout="column"
             fxLayoutAlign="center center">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>

          <h5 class="mat-h5 mv-8 lh-20 fw-600 ta-center">Prebieha nahrávanie fotografie</h5>
          <h5 class="mat-h5 mv-0 lh-16 ta-center">Počet zostávajúcich fotografií: {{ filesToUpload.length }}</h5>

          <button (click)="onCancel()"
                  class="mt-8"
                  color="warn"
                  mat-button
                  mat-flat-button>Zrušiť
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
