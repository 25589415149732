import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {checkAlbumName} from '../../../../../validators/album-name.validator';
import {SnackbarService} from '../../../../../services/snackbar.service';
import {AdminService} from '../../../../../services/admin.service';
import {Album} from '../../../../../interfaces/album.interface';

@Component({
  selector: 'app-rename-album-dialog',
  templateUrl: './rename-album-dialog.component.html',
  styleUrls: ['../../../../../../styles-dialog.scss']
})
export class RenameAlbumDialogComponent {
  public form: FormGroup = new FormGroup({
    name: new FormControl(this.data.album.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(128),
      checkAlbumName(this.data.albums)
    ])
  });

  constructor(private dialogRef: MatDialogRef<RenameAlbumDialogComponent>,
              private snackbarService: SnackbarService,
              private adminService: AdminService,
              @Inject(MAT_DIALOG_DATA) public data: {
                albums: Album[],
                album: Album
              }) {
  }

  hasError(control: string, error: string) {
    const errorCount = this.form.controls[control].errors ? Object.keys(this.form.controls[control].errors).length : 0;
    return this.form.controls[control].hasError(error) && errorCount === 1;
  }

  close(result = null) {
    this.dialogRef.close(result);
  }

  onSubmit() {
    this.form.markAsPristine();

    this.adminService.renameAlbum(this.data.album.id, this.form.value.name).subscribe(
      () => {
        this.snackbarService.add('Album premenovaný');
        this.close(true);
      },
      e => {
        this.snackbarService.addStatusError(+e.statusText);
      }
    );
  }
}
