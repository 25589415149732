import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  openDialog<T>(dialog, data = null, callback = null): MatDialogRef<T> {
    const dialogRef = this.dialog.open<T>(dialog, {
      width: environment.DIALOG_WIDTH,
      data
    });

    if (callback !== null) {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          callback(result);
        }
      });
    }

    return dialogRef;
  }
}
