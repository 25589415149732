<h1 class="mat-h1 mh-16 mt-16 mb-0">Administrácia</h1>
<h2 class="mat-h2 mh-16 mt-8 mb-0">Fotogaléria</h2>

<div class="mh-16 mt-8 mb-16">
  <h3 class="mat-h3 lh-40"
      fxLayout="row">Albumy
    <button (click)="onCreateAlbum()"
            [disabled]="albums === null"
            mat-button
            mat-icon-button
            matTooltip="Vytvoriť nový album"
            matTooltipPosition="after">
      <mat-icon>add</mat-icon>
    </button>
  </h3>

  <div *ngIf="albums === null"
       class="p-16 ta-center">
    <mat-spinner class="mh-auto mv-16"></mat-spinner>
    <h3 class="mat-h3 m-0">Prebieha načítavanie albumov...</h3>
  </div>

  <ng-container *ngIf="albums !== null">
    <div *ngIf="albums.length > 0"
         class="mr--16 mb--16"
         fxLayout="row wrap">
      <mat-card *ngFor="let album of albums"
                class="album o-hidden p-0 mr-16 mb-16"
                fxFlex="100%"
                fxFlex.gt-md="0 1 calc(25% - 16px)"
                fxFlex.gt-sm="0 1 calc(50% - 16px)">
        <h4 class="mat-h4 m-16 lh-40"
            fxLayout="row">
          <a [routerLink]="'/admin/fotogaleria/album/' + album.slug">{{ album.name }}
            <span class="album__photo-count">({{ album.photos.length }})</span></a>
          <span class="flex-spacer"></span>
          <button [matMenuTriggerFor]="album_menu"
                  mat-button
                  mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #album_menu="matMenu">
            <button (click)="onRenameAlbum(album)"
                    mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Premenovať</span>
            </button>
            <button (click)="onDeleteAlbum(album)"
                    mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Odstrániť</span>
            </button>
          </mat-menu>
        </h4>
        <mat-card-content class="m-0 p-0">
          <div *ngIf="album.photos.length === 0"
               class="p-16 ta-center">Žiadne fotky
          </div>
          <div class="mt--2 mr--2 mb--2"
               fxLayout="row wrap">
            <div *ngFor="let photo of album.photos | slice:0:16"
                 class="mr-2 mb-2"
                 fxFlex="0 1 calc(25% - 2px)"
                 fxFlex.gt-sm="0 1 calc(12.5% - 2px)">
              <div [ngStyle]="{'background-image': 'url(' + ENDPOINT_UPLOADS_THUMBNAILS +  photo.filename + ')'}"
                   class="album__photo cover"></div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="albums.length === 0">Zatiaľ nie sú vytvorené žiadne albumy</div>
  </ng-container>
</div>
