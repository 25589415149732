import {Component} from '@angular/core';

import {Branch} from '../../interfaces/branch.interface';
import {branches} from '../../const/branches.const';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  branches: Branch[] = branches;
}
