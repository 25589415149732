import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appDropZone]'
})
export class DropZoneDirective {
  @Output('appDropZone') fileDrop = new EventEmitter<Array<File>>();
  @Input() preventBodyDrop = true;

  @HostBinding('class.active')
  private active = false;

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.active = false;

    const {dataTransfer} = e;

    if (dataTransfer.items) {
      const files = [];
      for (let i = 0; i < dataTransfer.items.length; i++) {
        if (dataTransfer.items[i].kind === 'file') {
          files.push(dataTransfer.items[i].getAsFile());
        }
      }

      dataTransfer.items.clear();
      this.fileDrop.emit(files);
    } else {
      const files = dataTransfer.files;
      dataTransfer.clearData();
      this.fileDrop.emit(Array.from(files));
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.active = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.active = false;
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(e: DragEvent) {
    if (this.preventBodyDrop) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }

  @HostListener('body:drop', ['$event'])
  onBodyDrop(e: DragEvent) {
    if (this.preventBodyDrop) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }
}
