import {Branch} from '../interfaces/branch.interface';

export const branchHviezdoslavova: Branch = {
  name: 'Prevádzka Hviezdoslavova',
  contact: {
    phone: ['0915 747 000', '055 625 37 16'],
    email: ['kvetinarstvodagmar@gmail.com']
  },
  openingHours: [
    ['8:00', '19:00'], // Monday
    ['8:00', '19:00'], // Tuesday
    ['8:00', '19:00'], // Wednesday
    ['8:00', '19:00'], // Thursday
    ['8:00', '19:00'], // Friday
    ['8:00', '19:00'], // Saturday
    undefined, // Sunday
  ],
  address: {
    street: 'Hviezdoslavova',
    number: 7,
    zip: '04001',
    city: 'Košice',
    map: 'Kvetinárstvo u Dagmary (Hviezdoslavova 7): Hviezdoslavova 1008/7, 040 01 Košice'
  }
};
