import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Photo} from '../interfaces/photo.interface';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient) {
  }

  createAlbum(name: string): Observable<any> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.ALBUM}`;
    return this.http.post<any>(url, {
      name
    });
  }

  renameAlbum(id: number, name: string): Observable<any> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.ALBUM}/${id}`;
    return this.http.put<any>(url, {
      name
    });
  }

  setPhotos(id: number, photos: string): Observable<any> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.ALBUM}/${id}`;
    return this.http.put<any>(url, {
      id,
      photos
    });
  }

  deleteAlbum(id: number): Observable<any> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.ALBUM}/${id}`;
    return this.http.delete<any>(url);
  }

  addFile(file: File): Observable<any> {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.PHOTO}`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Photo>(url, formData);
  }

  deletePhoto(id: number) {
    const url = `${environment.ENDPOINT.HOST}${environment.ENDPOINT.ADMIN.PHOTO}/${id}`;
    return this.http.delete<any>(url);
  }
}
